@use "~bitstyles/scss/bitstyles/tools/palette";

:root,
[data-theme="default"] {
  --theme-background-color: #{palette.get("white")};
  --theme-color: #{palette.get("text", "default")};
  --sl-theme-link-color: #{palette.get("text", "default")};
  --sl-theme-link-color-hover: #{palette.get("brand-1", "hover")};
}

[data-theme="dark"] {
  --theme-background-color: #{palette.get("background", "dark")};
  --theme-color: #{palette.get("white")};
  --sl-theme-link-color: #{palette.get("white", "default")};
  --sl-theme-link-color-hover: #{palette.get("sl-yellow", "default")};
}

/* stylelint-disable selector-max-type */
body,
[data-theme] {
  background-color: var(--theme-background-color);
  color: var(--theme-color);
}
/* stylelint-enable selector-max-type */
