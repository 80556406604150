@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/atoms/button" as button-settings;

@use "~bitstyles/scss/bitstyles/atoms/button--icon" with
  (
    //
    // Base styles ////////////////////////////////////////
    $padding-vertical: size.get("m"),
    $padding-horizontal: size.get("m"),
    //
    // Base colors ////////////////////////////////////////
    $color: var(--theme-color, palette.get("white", "default")),
    $background-color: transparent,
    $border-color: transparent,
    $border-radius: size.get("xs"),
    $box-shadow: none,
    //
    // Hover colors ////////////////////////////////////////
    $color-hover: palette.get("sl-yellow", "default"),
    $background-color-hover: transparent,
    $border-color-hover: transparent,
    $box-shadow-hover: none,
    //
    // Active colors ////////////////////////////////////////
    $color-active: palette.get("sl-yellow", "default"),
    $background-color-active: transparent,
    $border-color-active: transparent,
    $box-shadow-active: none,
    //
    // Disabled colors ////////////////////////////////////////
    $color-disabled: palette.get("text", "light"),
    $background-color-disabled: palette.get("gray", "default"),
    $border-color-disabled: palette.get("gray", "default"),
    $box-shadow-disabled: none
  );

.a-button--icon {
  min-width: calc(#{button-settings.$padding-vertical * 2} + 1em);
  background-image: none;

  &::before {
    display: none;
  }
}
