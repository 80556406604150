@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";

.floating-label {
  position: relative;
}

.floating-label__label > .apos-area {
  display: flex;
  align-items: center;
}

.floating-label__label {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: size.get("s") size.get("m");
  transform-origin: top left;
  transition: transform 0.1s linear;
  color: palette.get("text", "placeholder");
}

/* stylelint-disable-next-line selector-max-type */
.floating-label__input:not(:placeholder-shown) + .floating-label__label,
.floating-label__input:focus + .floating-label__label {
  transform: scale(0.75) translateY(-0.85rem);
}

.floating-label__input {
  padding-top: size.get("m");
  padding-bottom: size.get("xxxs");
}
