@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/media-query";

@use "~bitstyles/scss/bitstyles/utilities/margin" with
  (
    $sizes: (
      "positive": (
        "0": 0,
        "xxs": size.get("xxs"),
        "xs": size.get("xs"),
        "s": size.get("s"),
        "m": size.get("m"),
        "l": size.get("l"),
        "xl": size.get("xl"),
        "block-s": size.get("block-s"),
        "block-m": size.get("block-m"),
        "block-l": size.get("block-l"),
        "block-xl": size.get("block-xl"),
        "slim-section": size.get("block-l"),
        "auto": auto,
      ),
      "negative": (
        "xxs": -#{size.get("xxs")},
        "xs": -#{size.get("xs")},
        "s": -#{size.get("s")},
        "m": -#{size.get("m")},
        "l": -#{size.get("l")},
        "xl": -#{size.get("xl")},
        "block-s": -#{size.get("block-s")},
        "block-m": -#{size.get("block-m")},
        "block-l": -#{size.get("block-l")},
        "block-xl": -#{size.get("block-xl")},
      ),
    ),
    $breakpoints: (
      "s",
      "m",
      "l",
      "xl",
    )
  );

.u-margin-0-last-child:last-child {
  margin-bottom: 0;
}
