@use "~bitstyles/scss/bitstyles/tools/media-query";

.u-width-auto {
  width: auto;
}

.u-width-full {
  width: 100%;
}

@include media-query.get("m") {
  .u-width-auto\@m {
    width: auto;
  }
}
