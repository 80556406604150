@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/media-query";

@use "~bitstyles/scss/bitstyles/atoms/content" with
  (
    $max-width: (
      "xs": 25rem,
      "s": 35rem,
      "m": 48rem,
      "l": 77.5rem,
      "full": 100%,
      "slim": 46rem,
    ),
    $max-width-base: "l",
    $padding: (
      "base": size.get("l"),
      "l": size.get("xl"),
      "xl": size.get("block-s"),
    )
  );

.a-content--slim {
  margin-bottom: 6rem;
}

.a-content {
  .a-content {
    padding-right: 0;
    padding-left: 0;
  }
}
