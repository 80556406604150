@use "~bitstyles/scss/bitstyles/tools/size";

@use "~bitstyles/scss/bitstyles/utilities/gap" with
  (
    $sizes: (
      "xs": size.get("xs"),
      "s": size.get("s"),
      "m": size.get("l"),
      "l": size.get("xl"),
      "xl": size.get("xl"),
      "block-s": size.get("block-s"),
      "block-m": size.get("block-m"),
      "block-l": size.get("block-l"),
    ),
    $breakpoints: (
      "m",
      "xl",
    )
  );
