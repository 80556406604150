@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/color";
@use "~bitstyles/scss/bitstyles/base/input-checkbox" with
  (
    $border-radius: size.get("xxs"),
    $gap: size.get("xs"),
    $border-width: size.get("xxxs"),
    $size: size.get("l"),
    $color: palette.get("text"),
    $background-color: palette.get("white"),
    $border-color: palette.get("sl-blue", "dark"),
    $color-hover: palette.get("sl-blue", "light"),
    $background-color-hover: palette.get("white"),
    $border-color-hover: palette.get("sl-blue", "light"),
    $color-checked: palette.get("white"),
    $background-color-checked: palette.get("sl-blue", "dark"),
    $border-color-checked: palette.get("sl-blue", "dark"),
    $background-image-checked:
      url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{color.url-encode-color(#ffffff)}' d='m83.07 11.71-44.41 44.59-21.73-21.81-15.93 15.99 37.65 37.81 60.35-60.59z'/%3E%3C/svg%3E"),
    $color-disabled: palette.get("text", "light"),
    $background-color-disabled: palette.get("text", "light"),
    $border-color-disabled: palette.get("text", "light"),
    $color-disabled-checked: palette.get("text", "light"),
    $background-color-disabled-checked: palette.get("text", "light"),
    $border-color-disabled-checked: palette.get("text", "light")
  );
