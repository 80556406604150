@use "~bitstyles/scss/bitstyles/settings/animation";
@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "~bitstyles/scss/bitstyles/settings/layout";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "../settings/typography";
@use "sass:list";
@use "sass:map";

$transition: color animation.$transition-duration animation.$transition-easing,
  background-color animation.$transition-duration animation.$transition-easing,
  border animation.$transition-duration animation.$transition-easing,
  box-shadow animation.$transition-duration animation.$transition-easing !default;

@use "~bitstyles/scss/bitstyles/atoms/button" with
  (
    //
    // Base styles ////////////////////////////////////////
    $font:
      list.slash(
        font-size.get("h4", "base"),
        map.get(typography.$line-heights, "h4")
      )
      typography.$webfont-family-name-sans,
    $font-weight: typography.$font-weight-bold,
    $padding-vertical: size.get("s"),
    $padding-horizontal: size.get("block-s"),
    $border-radius: layout.$border-radius-round,
    $transition: $transition,
    $icon-spacing: size.get("xs"),
    $border-width: 0,
    //
    // Base colors ////////////////////////////////////////
    $color: var(--theme-color, #{palette.get("sl-blue", "dark")}),
    $background-color: transparent,
    $border-color: transparent,
    $box-shadow: none,
    //
    // Hover colors ////////////////////////////////////////
    $color-hover: var(--theme-color, #{palette.get("white", "default")}),
    $background-color-hover: transparent,
    $border-color-hover: transparent,
    $box-shadow-hover: none,
    //
    // Active colors ////////////////////////////////////////
    $color-active: var(--theme-color, #{palette.get("white", "default")}),
    $background-color-active: transparent,
    $border-color-active: transparent,
    $box-shadow-active: none,
    //
    // Disabled colors ////////////////////////////////////////
    $color-disabled: palette.get("gray", "dark"),
    $background-color-disabled: palette.get("gray", "light"),
    $border-color-disabled: palette.get("gray", "light"),
    $box-shadow-disabled: none
  );

/* stylelint-disable selector-max-type */

.a-button {
  // NOTE:
  // Removes grey highlight when tapping links or buttons in mobile Safari.
  // Since we have styling for :active, the extra highlight is irritating.
  -webkit-tap-highlight-color: transparent;

  &::before {
    content: "";
    position: absolute;
    top: size.get("xxs");
    right: size.get("xxs");
    bottom: size.get("xxs");
    left: size.get("xxs");
    border-radius: layout.$border-radius-round;
    background-color: var(
      --theme-background-color,
      #{palette.get("white", "default")}
    );
  }

  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    background-image: linear-gradient(
      270deg,
      palette.get("sl-yellow", "default"),
      palette.get("sl-pink", "default")
    );
  }

  &:hover,
  &:focus {
    &::before {
      background-color: transparent;
    }
  }
}

.a-button__title {
  z-index: 1;
}

.a-button--secondary {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    background-color: palette.get("sl-blue", "light");
    background-image: none;
  }
}
/* stylelint-enable selector-max-type */
