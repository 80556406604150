@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/utilities/bg" with
  (
    $values: (
      "transparent": transparent,
      "default": palette.get("background", "default"),
      "red": palette.get("brand-1", "default"),
      "gray": palette.get("gray", "default"),
      "gray-light": palette.get("gray", "light"),
      "gray-dark": palette.get("gray", "dark"),
      "white": palette.get("white"),
      "blue": palette.get("blue", "light"),
    ),
    $breakpoints: ()
  );
