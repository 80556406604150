@use "~bitstyles/scss/bitstyles/base/typography" as base-typography;
@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/font-size";

@use "../settings/typography";
@use "sass:map";

/* stylelint-disable selector-max-type */
h1 {
  line-height: map.get(typography.$line-heights, "h1");
}

h2 {
  line-height: map.get(typography.$line-heights, "h2");
}

h3 {
  line-height: map.get(typography.$line-heights, "h3");
}

h4 {
  line-height: map.get(typography.$line-heights, "h4");
}

h5 {
  line-height: map.get(typography.$line-heights, "h5");
}

h6 {
  line-height: map.get(typography.$line-heights, "h6");
}
/* stylelint-enable selector-max-type */
