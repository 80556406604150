@use "../settings/typography";
@use "~bitstyles/scss/bitstyles/utilities/typography" as utilities-typography;
@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "sass:map";

.u-h0 {
  line-height: map.get(typography.$line-heights, "h0");
}

.u-h1 {
  line-height: map.get(typography.$line-heights, "h1");
}

.u-h2 {
  line-height: map.get(typography.$line-heights, "h2");
}

.u-h3 {
  line-height: map.get(typography.$line-heights, "h3");
}

.u-h4 {
  line-height: map.get(typography.$line-heights, "h4");
}

.u-h5 {
  line-height: map.get(typography.$line-heights, "h5");
}

.u-h6 {
  line-height: map.get(typography.$line-heights, "h6");
}

.u-text {
  font-size: font-size.get("text", "base");
}
