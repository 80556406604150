@use "~bitstyles/scss/bitstyles/tools/shadows";
@use "~bitstyles/scss/bitstyles/tools/shadow";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";

@use "~bitstyles/scss/bitstyles/utilities/drop-shadow" with
  (
    $values: (
      "black": shadows.get("black", "drop"),
      "gray": shadows.get("gray", "drop"),
    )
  );
