@use "~bitstyles/scss/bitstyles/utilities/overflow" with
  (
    $values: (
      "overflow": (
        "hidden": hidden,
        "scroll": scroll,
        "auto": auto,
      ),
      "overflow-x": (
        "auto": auto,
        "hidden": hidden,
      ),
      "overflow-y": (
        "auto": auto,
      ),
    )
  );
