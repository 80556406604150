@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "../settings/typography";
@use "sass:map";

$grid-template-columns: 11% 22% 8% 18% 8% 22% 11%;

.text-image__content {
  @include media-query.get("m") {
    display: grid;
    grid-template-columns: $grid-template-columns;
  }
}

.text-image__text {
  z-index: 1;
  grid-column: 2/5;
  grid-row: 1;
  margin-top: size.get("block-xl");

  @include media-query.get("m") {
    margin-top: size.get("block-xxl");
  }
}

.text-image__media {
  display: flex;
  grid-column: 6/8;
  grid-row: 1;
}

.text-image__heading {
  margin-bottom: size.get("m");

  @include media-query.get("m") {
    margin-bottom: size.get("xl");
  }
}

.text-image__description {
  margin-bottom: size.get("xl");
}

.text-image__body-text {
  margin: size.get("xl") 0;
}

.text-image__buttons {
  margin-top: size.get("block-xl");

  .apos-area {
    display: flex;
    flex-wrap: wrap;

    @include media-query.get("l") {
      justify-content: flex-end;
    }
  }

  .a-button {
    margin-bottom: size.get("l");

    /* stylelint-disable max-nesting-depth */
    @include media-query.get("l") {
      &:last-child {
        margin-left: size.get("block-s");
      }
    }
    /* stylelint-enable max-nesting-depth */
  }
}

.text-image__image {
  width: 130vw;
  max-width: none;
  height: auto;

  @include media-query.get("m") {
    max-width: 51rem;
    height: auto;
  }
}

.text-image--alternating-theme {
  background-color: var(--theme-background-color);
  color: var(--theme-color);

  .text-image__text {
    @include media-query.get("m") {
      padding-bottom: size.get("block-xl");
    }
  }

  .text-image__media {
    align-items: flex-end;
    padding-top: size.get("xl");
  }

  &:nth-child(odd) {
    --theme-background-color: #{palette.get("background", "dark")};
    --theme-color: #{palette.get("white")};
  }

  &:nth-child(even) {
    --theme-background-color: #{palette.get("sl-blue", "lighter")};
    --theme-color: #{palette.get("sl-blue", "dark")};
  }
}

.text-image--alternating-order:nth-child(odd),
.text-image--reverse-alternating-order:nth-child(even) {
  .text-image__text {
    grid-column: 4/7;
  }

  .text-image__media {
    grid-column: 1/3;
    justify-content: flex-end;
  }
}

@include media-query.get("s") {
  .text-image--features {
    .text-image__content {
      display: grid;
      grid-template-columns: $grid-template-columns;
    }

    .text-image__text {
      grid-column: 1/6;
    }

    .text-image__media {
      grid-column: 4/9;
      grid-row: 2;
      margin-top: -35%;
    }

    &:nth-child(even) {
      .text-image__text {
        grid-column: 3/8;
      }

      .text-image__media {
        grid-column: 1/6;
        justify-content: flex-end;
      }
    }
  }
}
