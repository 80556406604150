@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "../settings/typography";
@use "sass:map";

.faq {
  border-radius: size.get("s");
  background-color: palette.get("gray", "light");

  &:active,
  &:focus,
  &:hover {
    background-color: palette.get("gray", "default");
  }

  &[open] {
    background-color: palette.get("sl-blue", "lighter");
  }
}

.faq__title {
  margin-top: size.get("block-l");
  margin-bottom: size.get("block-s");
  font-size: font-size.get("h3", "base");
  line-height: map.get(typography.$line-heights, "h3");

  @include media-query.get("m") {
    font-size: font-size.get("h3", "m");
  }
}

.faq__list-item {
  margin-bottom: size.get("xs");
}

.faq__question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: size.get("m") size.get("l") size.get("m") size.get("xl");
  list-style-type: none;
  font-size: font-size.get("h4", "base");
  font-weight: typography.$font-weight-bold;
  line-height: map.get(typography.$line-heights, "h4");
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &::after {
    content: "";
    flex-shrink: 0;
    align-self: flex-start;
    width: 2rem;
    height: 2rem;
    margin-left: size.get("l");
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M16 6c.563 0 1 .438 1 1v8h8c.563 0 1 .438 1 1 0 .563-.438 1-1 1h-8v8c0 .563-.438 1-1 1-.563 0-1-.438-1-1v-8H7c-.563 0-1-.438-1-1 0-.563.438-1 1-1h8V7c0-.563.438-1 1-1z' fill='%2300648b' fill-rule='evenodd' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &:hover::after,
  &:focus::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M16 6c.563 0 1 .438 1 1v8h8c.563 0 1 .438 1 1 0 .563-.438 1-1 1h-8v8c0 .563-.438 1-1 1-.563 0-1-.438-1-1v-8H7c-.563 0-1-.438-1-1 0-.563.438-1 1-1h8V7c0-.563.438-1 1-1z' fill='%23003246' fill-rule='evenodd' /%3E%3C/svg%3E");
  }

  &:active::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M16 6c.563 0 1 .438 1 1v8h8c.563 0 1 .438 1 1 0 .563-.438 1-1 1h-8v8c0 .563-.438 1-1 1-.563 0-1-.438-1-1v-8H7c-.563 0-1-.438-1-1 0-.563.438-1 1-1h8V7c0-.563.438-1 1-1z' fill='%23003246' fill-rule='evenodd' /%3E%3C/svg%3E");
  }

  [open] &::after,
  [open] &:focus::after,
  [open] &:hover::after,
  [open] &:active::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M7 17h18c.564 0 1-.5 1-1 0-.563-.438-1-1-1H7c-.564 0-1 .5-1 1 0 .563.438 1 1 1z' fill='%23003246' fill-rule='evenodd' /%3E%3C/svg%3E");
  }
}

.faq__answer {
  padding: 0 size.get("xl") size.get("xl");
  font-size: font-size.get("text", "base");
}
