@use "sass:list";

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-black: 800;
$webfont-family-name-head: "DBScreenHead";
$webfont-family-name-sans: "DBScreenSans";
$line-heights: (
  "h0": 0.9,
  "h1": 1.29,
  "h2": 1.2,
  "h3": 1.33,
  "h4": 1.5,
  "h5": 1.43,
  "h6": 1.67,
);

@use "~bitstyles/scss/bitstyles/settings/typography" with
  (
    $webfont-family-name: $webfont-family-name-sans,
    $webfont-base-url: "https://assets.bahn-x.de/fonts/latest/",
    $webfont-variants: (
      "head-black": (
        "font-family": $webfont-family-name-head,
        "font-style": normal,
        "font-weight": $font-weight-black,
        "filename": "DBScreenHead-Black",
      ),
      "head-regular": (
        "font-family": $webfont-family-name-head,
        "font-style": normal,
        "font-weight": $font-weight-normal,
        "filename": "DBScreenHead-Regular",
      ),
      "head-light": (
        "font-family": $webfont-family-name-head,
        "font-style": normal,
        "font-weight": $font-weight-light,
        "filename": "DBScreenHead-Light",
      ),
      "bold": (
        "font-family": $webfont-family-name-sans,
        "font-style": normal,
        "font-weight": $font-weight-bold,
        "filename": "DBScreenSans-Bold",
      ),
      "italic": (
        "font-family": $webfont-family-name-sans,
        "font-style": italic,
        "font-weight": $font-weight-normal,
        "filename": "DBScreenSans-Italic",
      ),
      "regular": (
        "font-family": $webfont-family-name-sans,
        "font-style": normal,
        "font-weight": $font-weight-normal,
        "filename": "DBScreenSans-Regular",
      ),
    ),
    $font-family-header: $webfont-family-name-head,
    $font-sizes: (
      "base": (
        "html": 1rem,
        "h0": 2.5rem,
        // Title.XL
        "h1": 2.25rem,
        // Title.L
        "h2": 1.75rem,
        // Title.M
        "h3": 1.5rem,
        // Title.S
        "h4": 1.125rem,
        // copy.S
        "h5": 1rem,
        "h6": 0.75rem,
        "body": 1rem,
        "text": 1rem,
      ),
      "m": (
        "html": 1rem,
        "h0": 2.6667rem,
        "h1": 3.25rem,
        "h2": 2.25rem,
        "h3": 2rem,
        "h4": 1.25rem,
        "h5": 1.125rem,
        "h6": 0.7777rem,
        "body": 1rem,
      ),
    )
  );
