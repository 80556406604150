@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/palette";

/* stylelint-disable selector-max-type, declaration-no-important */

.apos-admin-bar-spacer {
  margin-bottom: 0 !important;
}

.apos-area-widget-inner {
  padding-top: size.get("xl");
  padding-bottom: size.get("xl");
}

.apos-button--tiny {
  padding: size.get("l");
}

.apos-button--tiny svg {
  width: size.get("l");
  height: size.get("l");
}

.apos-button--primary.apos-button--tiny:disabled {
  border-style: none;
  background-color: palette.get("gray", "default");
  color: palette.get("gray", "dark");
}

/* stylelint-enable selector-max-type, declaration-no-important */
