@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "../tools/pulse";
@use "~bitstyles/scss/bitstyles/tools/palette";

.hero {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  justify-content: center;
  height: 90vh;
  min-height: 40rem;
  color: palette.get("white", "default");
  text-align: center;
}

.hero__background {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.hero__background::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to top,
    rgba(0, 50, 70, 80%),
    palette.get("sl-blue", "dark")
  );
}

.hero__background-image {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  object-fit: cover;
}

.hero__content {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  padding: size.get("block-xxl") size.get("l") size.get("block-l");

  @include media-query.get("l") {
    padding-right: size.get("xl");
    padding-left: size.get("xl");
  }

  @include media-query.get("xl") {
    padding-right: size.get("block-s");
    padding-left: size.get("block-s");
  }
}

.hero__heading {
  overflow-wrap: break-word;
  word-break: break-word;
}

.hero__media {
  order: -1;
  width: 30%;
  margin-bottom: size.get("block-m");

  @include media-query.get("m") {
    width: 100%;
    max-width: 14rem;
  }
}
