@use "~bitstyles/scss/bitstyles/tools/palette";

.a-link--nav {
  &,
  &:visited {
    color: var(--sl-theme-link-color, #{palette.get("black", "default")});
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: var(
      --sl-theme-link-color-hover,
      #{palette.get("sl-yellow", "default")}
    );
    text-decoration: none;
  }
}
