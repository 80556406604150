@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/truncate";
@use "../settings/typography";

.a-button--sidebar {
  justify-content: flex-start;
  width: 100%;
  padding: size.get("l");
  font-weight: typography.$font-weight-normal;
}

.a-button--sidebar *:last-child {
  @include truncate.truncate;
}
