@use "~bitstyles/scss/bitstyles/settings/layout";
@use "~bitstyles/scss/bitstyles/settings/typography";
@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";

.a-button--top-nav {
  min-width: 0;
  padding: size.get("s") size.get("m");
  font-size: font-size.get("h5");
  font-weight: typography.$font-weight-bold;
}

.a-button--sidebar,
.a-button--top-nav {
  &::before {
    display: none;
  }

  &,
  &:visited {
    border-color: transparent;
    background: transparent;
    color: var(--sl-theme-link-color, #{palette.get("white", "default")});
  }

  &:hover,
  &:focus {
    border-color: transparent;
    background: transparent;
    color: var(
      --sl-theme-link-color-hover,
      #{palette.get("sl-yellow", "default")}
    );
  }

  &:active {
    border-color: transparent;
    background: transparent;
  }

  &[aria-pressed="true"],
  &[aria-expanded="true"],
  &[aria-current] {
    border-color: transparent;
    background: palette.get("sl-yellow", "default");
    color: palette.get("background");
  }

  &:disabled,
  &[aria-disabled="true"] {
    border-color: palette.get("gray", "default");
    background: rgba(palette.get("gray", "default"), 0.5);
    color: palette.get("text", "light");
  }
}
