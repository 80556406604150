@use "../settings/typography";

@use "~bitstyles/scss/bitstyles/utilities/font" with
  (
    $weight-values: (
      "light": typography.$font-weight-light,
      "normal": typography.$font-weight-normal,
      "bold": typography.$font-weight-bold,
      "black": typography.$font-weight-black,
    ),
    $weight-breakpoints: (),
    $style-values: (
      "italic": italic,
      "not-italic": normal,
    ),
    $style-breakpoints: (),
    $family-values: (
      "head": typography.$webfont-family-name-head,
      "body": typography.$webfont-family-name-sans,
    ),
    $family-breakpoints: ()
  );
