@use "~bitstyles/scss/bitstyles/tools/size";

@use "~bitstyles/scss/bitstyles/utilities/border-radius" with
  (
    $values: (
      "xs": size.get("xs"),
      "s": size.get("s"),
      "m": size.get("m"),
      "l": size.get("l"),
    )
  );
