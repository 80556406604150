@use "~bitstyles/scss/bitstyles/utilities/grid-cols" with
  (
    $values: (
      "2": repeat(2, minmax(0, 1fr)),
      "3": repeat(3, minmax(0, 1fr)),
      "4": repeat(4, minmax(0, 1fr)),
      "6": repeat(6, minmax(0, 1fr)),
    ),
    $breakpoints: (
      "s",
      "m",
      "l",
      "xl",
    )
  );
