@use "~bitstyles/scss/bitstyles/tools/media-query";

@use "~bitstyles/scss/bitstyles/utilities/height" with
  (
    $values: (
      "auto": auto,
      "100vh": 100vh,
      "stretch": stretch,
      "benefits-image": 6rem,
      "full": 100%,
    )
  );
