@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/palette";

.side-nav {
  position: fixed;
  z-index: 40;
  inset: 0;
}

.side-nav__content {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 28rem;
  height: 100%;
  background-color: palette.get("sl-blue", "dark");
}

.side-nav__close-section {
  display: block;
  flex-grow: 1;
  border: none;
  background-color: rgb(0 0 0 / 35%);
}

.side-nav__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: size.get("l");
  padding-top: size.get("xs");
  padding-right: size.get("xxs");
}

.side-nav__button {
  margin-left: auto;
}

.side-nav__main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: size.get("xl");
  overflow-y: auto;
}

.side-nav__list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.side-nav__extra {
  margin: auto size.get("l") size.get("l");
}
