@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "../settings/typography";
@use "sass:map";

.intro {
  margin-bottom: size.get("block-s");

  @include media-query.get("m") {
    margin-bottom: size.get("block-xl");
  }
}

.intro__content {
  @include media-query.get("m") {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}

.intro__heading {
  grid-column: 1;
  margin-bottom: size.get("xl");
  font-size: font-size.get("h1", "base");
  line-height: map.get(typography.$line-heights, "h1");

  @include media-query.get("m") {
    font-size: font-size.get("h1", "m");
  }
}

.intro__body {
  grid-column: 1;
}
