@use "sass:math";

$size-base: 1rem;

@use "~bitstyles/scss/bitstyles/settings/layout" with
  (
    $global-z: (
      "topbar",
    ),
    $border-radius-round: 9999rem,
    $size-base: $size-base,
    $sizes: (
      "0": 0,
      "xxxs": 0.125rem,
      "xxs": 0.25rem,
      "xs": 0.5rem,
      "s": 0.75rem,
      "m": $size-base,
      "l": 1.5rem,
      "xl": 1.75rem,
      "xxl": 2.5rem,
      "block-s": 2.5rem,
      "block-m": 3rem,
      "block-l": 4rem,
      "block-xl": 5rem,
      "block-xxl": 7.5rem,
      "block-xxxl": 9rem,
    )
  );
