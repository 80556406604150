@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/size";

.section {
  padding-top: size.get("block-xxl");
  padding-bottom: size.get("block-xxl");

  @include media-query.get("m") {
    padding-top: size.get("block-xxxl");
    padding-bottom: size.get("block-xxxl");
  }

  &:last-child {
    padding-bottom: 0;
  }
}
