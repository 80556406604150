@use "~bitstyles/scss/bitstyles/tools/size";

@use "~bitstyles/scss/bitstyles/atoms/icon" with
  (
    $sizes: (
      "s": size.get("s"),
      "m": size.get("m"),
      "l": 1.5rem,
      "xl": size.get("xl"),
      "xxl": 2rem,
    )
  );
