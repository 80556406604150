@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";

.footer {
  display: flex;
  flex-flow: wrap column;
  gap: size.get("s");
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: size.get("l");
  padding-bottom: size.get("m");
  font-size: font-size.get("h6", "base");

  @include media-query.get("m") {
    font-size: font-size.get("h5", "m");
  }

  @include media-query.get("l") {
    flex-direction: row;
    padding-top: size.get("xxl");
    padding-bottom: size.get("block-xl");
  }
}

.footer__list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.footer__list-item {
  margin-right: size.get("l");

  @include media-query.get("l") {
    margin-right: size.get("block-l");
  }

  &:last-child {
    margin: 0;
  }
}

.footer__copyright {
  font-style: italic;

  @include media-query.get("s") {
    margin-top: size.get("l");
  }
}
