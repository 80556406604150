@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "../settings/typography";
@use "sass:map";

.benefits__list {
  display: grid;
  gap: size.get("block-l");

  @include media-query.get("m") {
    gap: size.get("block-m");
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include media-query.get("l") {
    gap: size.get("l");
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.benefit {
  text-align: center;
}

.benefit__title {
  margin-bottom: size.get("s");
  font-family: typography.$webfont-family-name-head;
  font-size: font-size.get("h3", "base");
  font-weight: typography.$font-weight-light;
  line-height: map.get(typography.$line-heights, "h3");

  @include media-query.get("m") {
    margin-bottom: size.get("l");
    font-size: font-size.get("h3", "m");
  }
}

.benefit__description {
  @include media-query.get("m") {
    font-size: font-size.get("h4");
  }
}

.benefit__image {
  width: 6rem;
  height: auto;
  margin: 0 auto size.get("s");
}
