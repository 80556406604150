@use "~bitstyles/scss/bitstyles/tools/font-size";
@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "../settings/typography";
@use "sass:map";

/* stylelint-disable selector-max-type */
.rich-text p {
  margin-bottom: size.get("m");

  &:last-child {
    margin-bottom: 0;
  }
}

/* stylelint-disable no-descending-specificity */
.rich-text li > p {
  margin-bottom: 0;
}
/* stylelint-enable no-descending-specificity */
/* stylelint-enable selector-max-type */
