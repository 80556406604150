@use "~bitstyles/scss/bitstyles/settings/typography";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/settings/animation";
@use "~bitstyles/scss/bitstyles/tools/size";

@use "~bitstyles/scss/bitstyles/settings/link" with
  (
    //
    // Base styles /////////////////////////////////////////
    $transition: color animation.$transition-duration
      animation.$transition-easing,
    $font-weight: typography.$font-weight-normal,
    //
    // Base Colors /////////////////////////////////////////
    $color: palette.get("brand-1", "default"),
    $text-decoration: none,
    $background-color: transparent,
    $box-shadow: none,
    //
    // Visited /////////////////////////////////////////
    $color-visited: palette.get("brand-1", "default"),
    $text-decoration-visited: none,
    $background-color-visited: none,
    $box-shadow-visited: none,
    //
    // Hover /////////////////////////////////////////
    $color-hover: palette.get("brand-1", "hover"),
    $text-decoration-hover: none,
    $background-color-hover: none,
    $box-shadow-hover: none,
    //
    // Active /////////////////////////////////////////
    $color-active: palette.get("brand-1", "default"),
    $text-decoration-active: none,
    $background-color-active: none,
    $box-shadow-active: none
  );
