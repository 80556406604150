@use "~bitstyles/scss/bitstyles/tools/media-query";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";

.header {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  padding-top: size.get("xs");
  background-color: transparent;

  @include media-query.get("l") {
    padding-top: size.get("xxl");
  }
}

.header__content {
  @include media-query.get("under-l") {
    padding-right: size.get("xxs");
  }
}

.header--slimpage {
  position: static;
}
