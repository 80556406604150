@use "../settings/typography";
@use "~bitstyles/scss/bitstyles/tools/palette";
@use "~bitstyles/scss/bitstyles/tools/size";
@use "~bitstyles/scss/bitstyles/base/input-text" with
  (
    //
    // Base styles ////////////////////////////////////////
    $padding-vertical: size.get("s"),
    $padding-horizontal: size.get("m"),
    $border-radius: size.get("xxs"),
    $border-width: 0,
    $font: inherit,
    $placeholder-font-style: italic,
    $placeholder-font-weight: typography.$font-weight-normal,
    $placeholder-color: palette.get("text", "light"),
    //
    // Base colors ////////////////////////////////////////
    $color: palette.get("text"),
    $border-color: palette.get("sl-blue", "light"),
    $background: rgba(palette.get("sl-blue", "dark"), 0.05),
    $box-shadow: none,
    //
    // Hover colors ////////////////////////////////////////
    $color-hover: palette.get("text"),
    $background-hover: rgba(palette.get("sl-blue", "dark"), 0.05),
    $border-color-hover: palette.get("sl-blue", "light"),
    $box-shadow-hover: none,
    //
    // Active colors ////////////////////////////////////////
    $color-active: palette.get("text"),
    $background-active: rgba(palette.get("sl-blue", "dark"), 0.15),
    $border-color-active: palette.get("sl-blue", "light"),
    $box-shadow-active: none,
    //
    // Disabled colors ////////////////////////////////////////
    $color-disabled: palette.get("text"),
    $background-disabled: palette.get("text", "light"),
    $border-color-disabled: palette.get("text", "light"),
    $box-shadow-disabled: none,
    //
    // Invalid colors ////////////////////////////////////////
    $color-invalid: palette.get("text"),
    $border-color-invalid: palette.get("sl-blue", "light"),
    $background-color-invalid: rgba(palette.get("sl-blue", "dark"), 0.05),
    $box-shadow-invalid: none
  );

/* stylelint-disable selector-max-type */
[type="text"],
[type="email"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="password"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
textarea {
  border-width: 0 0 size.get("xxxs");
}
/* stylelint-enable selector-max-type */
