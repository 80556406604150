@use "~bitstyles/scss/bitstyles/tools/media-query";

.logo {
  width: 9rem;
  height: auto;
  background-color: transparent;
  color: var(--sl-theme-link-color);

  @include media-query.get("l") {
    width: 10rem;
  }
}

.logo--sidenav {
  width: auto;
  height: 1.25rem;
}
